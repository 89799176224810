<template>
	<main>
		<section class="section hero-section">
			<div class="container">
				<div class="hero">
					<h2 class="hero__title">DATA ANNOTATION</h2>
				</div>
			</div>
		</section>
		<section class="section welcome-section bg-grey">
			<div class="container">
				<div class="section-content">
					<h2>Welcome to AV DEVS Image Annotation Services</h2>
					<p>
						Unlock the full potential of your computer vision projects with our premium, high-quality image annotation services. At AV DEVS,
						we offer a wide range of annotation, tracking, tagging, analytics, and logo recognition solutions tailored to the needs of
						various industries. With meticulous attention to detail, our expert team ensures that every pixel is accurately annotated and
						tagged.
					</p>
				</div>
			</div>
		</section>
		<section class="section benefits-section">
			<div class="container">
				<div class="benefits-container">
					<div class="section-head">
						<Subtitle>Complete Image Tagging</Subtitle>
						<h2 class="title">Our Comprehensive Image Annotation Solutions</h2>
					</div>
					<div class="benefits-grid">
						<div class="grid-item" v-for="(item, i) in $store.state.siteData.annotations" :key="i">
							<CardAlt :index="item.id" :title="item.title" :text="item.text" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section services-section bg-grey">
			<div class="container">
				<div class="two-col-grid">
					<div class="col image-col">
						<div class="image">
							<img src="../../assets/images/data-annotation.jpg" alt="" />
						</div>
					</div>
					<div class="col content-col">
						<div class="content">
							<Subtitle>Top Labeling Choice</Subtitle>
							<h2 class="title">Why Choose AV DEVS Image Annotation Services?</h2>
							<ul class="list">
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Expert annotators with experience in diverse industries, including automotive, medical, and gaming.</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>A multilingual team fluent in various languages for global reach.</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Dedicated quality assurance processes to ensure the highest level of accuracy.</span>
								</li>
								<li class="list-item">
									<span class="dot">
										<span></span>
									</span>
									<span>Customizable solutions to cater to your unique project requirements.</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapActions } from "vuex";
import Subtitle from "@/components/utils/Subtitle.vue";
import CardAlt from "@/components/utils/CardAlt.vue";

export default {
	name: "AboutPage",
	components: { Subtitle, CardAlt },
	metaInfo: {
		title: "The only technology partner you will ever need | Enterprise Solutions",
		meta: [
			{
				name: "description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
			{
				name: "og:title",
				content: "The only technology partner you will ever need | Enterprise Solutions",
			},
			{
				name: "og:description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
		],
	},
	methods: {
		...mapActions(["fillTargets"]),
	},
	mounted() {
		this.fillTargets();
	},
};
</script>

<style lang="scss" scoped>
.hero-section {
	background: url("~@/assets/images/about-bg.webp") center/cover;
}

.services-section {
	padding: 50px 0;
	background-color: $white;
}

.benefits-section {
	background-color: darken($white, 1%);
	.benefits-container {
		text-align: center;
		padding-bottom: 3.2rem;

		.benefits-grid {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			gap: 3vw;

			@media screen and (max-width: 1600px) {
				grid-template-columns: repeat(3, 1fr);
			}

			@media screen and (max-width: 992px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (max-width: 576px) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
}
</style>
